<template>
    <div class="view_comm">
      <div class="head_view">
        <div v-if="detailData.thumbnail" class="area_img" :style="{ 'background-image' : `url(${detailData.thumbnail})`}"></div>
        <Share
          v-if="isShare"
          :metaData="detailData"
          :itemRouteName="$route.name"
          :id="detailData.link || detailData.id"
          :tidy="16"/>
        <strong class="tit_view">{{ detailData.title }}</strong>
        <span class="date_view" v-if="isDate">{{ detailData.created_at | convertDateFormat('YYYY.MM.DD') }}</span>
      </div>
      <div class="body_view">
        <div class="editor_view" v-html="detailData.contents"></div>
      </div>
      <div class="foot_view" v-if="isFooterBtnControaler || isShare">
        <!-- <div
          v-if="isFooterBtnControaler"
          class="inner_foot_btn">
          <div class="area_left">
            <button class="btn_view_foot btn_view_prev">
              <IconSvg
                iconName="arrow_left"
                iconColor="#232323"
                :size="16"/>
              이전글
            </button>
          </div>
          <div class="area_right">
            <button class="btn_view_foot btn_view_next">
              다음글
              <IconSvg
                iconName="arrow_right"
                iconColor="#232323"
                :size="16"/>
            </button>
          </div>
        </div> -->
        <Share
          v-if="isShare"
          :metaData="detailData"
          :itemRouteName="$route.name"
          :id="detailData.link || detailData.id"
          :tidy="16"/>
      </div>
    </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg.vue';
import Share from '@/components/common/share/Share.vue';

export default {
  name:'BoardView',
  props:{
    detailData: Object,
    isDate: Boolean,
    isShare: Boolean,
    isFooterBtnControaler: Boolean
  },
  components:{
    IconSvg,
    Share
  },
  computed:{
    link(){
      const isProductionMode = process.env.NODE_ENV === 'production';
      const domain = isProductionMode ? 'https://www.firfin.family' : 'https://www.preprod.firfin.family'
      const path = this.$routerPath[camelToSnake(this.$route.name).toUpperCase()]
      const param = this.detailData.link || this.detailData.id;
      return `${domain}${path ? path.replace(':id',param) : ''}`
    }
  },
}
</script>
<style scoped>
.view_comm{max-width:840px;margin:0 auto;padding:160px 20px 0}
.view_comm:last-child{padding-bottom:200px}

.share_comm{text-align:right}

.head_view .area_img{width:100%;padding-top:52.382%;background-color:#DDD2BD;background-size:contain}
.head_view .share_comm{margin-top:32px}
.head_view .tit_view{display:block;margin-top:32px;font-weight:700;font-size:28px;line-height:36px;color:#111;word-wrap:break-word;word-break:keep-all}
.head_view .date_view{display:block;margin-top:16px;font-weight:600;font-size:16px;line-height:24px;color:#666}

.body_view{margin-top:32px;word-wrap:break-word;word-break:keep-all}
.body_view >>> .tit_sub{display:block;font-weight:700;font-size:24px;line-height:30px;color:#111}
.body_view >>> .desc_view{font-weight:500;font-size:16px;line-height:32px;color:#111}
.body_view >>> .tit_sub + .desc_view{margin-top:25px}
.body_view >>> .desc_view + .tit_sub{margin-top:32px}

.body_view >>> .editor_view p{min-height:32px;font-weight:500;font-size:16px;line-height:32px;color:#111}
.body_view >>> .editor_view h1,
.body_view >>> .editor_view h2,
.body_view >>> .editor_view strong{font-weight:700}
.body_view >>> .editor_view h1{font-size:28px;line-height:44px}
.body_view >>> .editor_view h2{font-size:22px;line-height:40px}
.body_view >>> .editor_view h3{font-weight:600;font-size:20px;line-height:34px}
.body_view >>> .editor_view [data-name="title_content"]{display:block;padding-top:32px;padding-bottom:25px;font-weight:700;font-size:24px;line-height:30px}
.body_view >>> .editor_view p:first-child [data-name="title_content"],
.body_view >>> .editor_view img + p [data-name="title_content"]{padding-top:0}
.body_view >>> .editor_view [data-name="txt_highlight"]{display:inline;padding:0 2px;background-color:#D8CBF4}
.body_view >>> .editor_view em{font-style:italic}
.body_view >>> .editor_view a{color:#579BF5;text-decoration:underline}
.body_view >>> .editor_view img{display:block;width:100%;margin:32px 0}
.body_view >>> .editor_view ul,
.body_view >>> .editor_view ul > li{list-style:disc}
.body_view >>> .editor_view ul{padding-left:20px;text-indent:-4px}
.body_view >>> .editor_view ul > li > ul,
.body_view >>> .editor_view ul > li > ul > li{list-style:circle}
.body_view >>> .editor_view ul > li > ul > li > ul,
.body_view >>> .editor_view ul > li > ul > li > ul > li{list-style:square}
.body_view >>> .editor_view ol,
.body_view >>> .editor_view ol > li{list-style:decimal}
.body_view >>> .editor_view ol{padding-left:26px}
.body_view >>> .editor_view ol > li > ol,
.body_view >>> .editor_view ol > li > ol > li{list-style:lower-latin}
.body_view >>> .editor_view ol > li > ol > li > ol,
.body_view >>> .editor_view ol > li > ol > li > ol > li{list-style:lower-roman}
.body_view >>> .editor_view ol > li > ol{padding-left:20px}
.body_view >>> .editor_view .btn_link{display:block;padding:14px;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;text-align:center;text-decoration:none}
.body_view >>> .editor_view .btn_primary{background-color:#BEA9ED;color:#111}
.body_view >>> .editor_view .btn_secondary{background-color:#232323;color:#fff}
.body_view >>> .editor_view .btn_secondary_tonal{background-color:#F3F1F1;color:#232323}
.body_view >>> .editor_view .btn_tertiary{background-color:#E23C3C;color:#fff}
.body_view >>> .editor_view .btn_tertiary_tonal{background-color:#F3F1F1;color:#E23C3C}
.body_view >>> .editor_view .btn_outlined{border:1px solid #A6A4A4;color:#232323}
.body_view >>> .editor_view blockquote{margin:8px 0;padding:0 14px;border-left:3px solid #111}
.body_view >>> .editor_view div[data-youtube-video]{position:relative;padding-top:56.25%;}
.body_view >>> .editor_view div[data-youtube-video] iframe{position:absolute;top:0;bottom:0;left:0;right:0;border:0 none;width: 100% !important;height:100% !important}

.foot_view .inner_foot_btn{overflow:hidden;margin-top:80px}
.foot_view .btn_view_foot{display:block;width:180px;height:56px;padding:14px 0;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#F3F1F1}
.foot_view .btn_view_prev .icon_firfin{margin:6px 12px 6px 0}
.foot_view .btn_view_next .icon_firfin{margin:6px 0 6px 12px}

.foot_view .share_comm{margin-top:32px}

/* 모바일 */
@media all and (max-width:1199px){
  .view_comm{padding-top:102px}
  .view_comm:last-child{padding-bottom:48px}

  .foot_view .btn_view_foot{width:148px}
}
</style>