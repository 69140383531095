import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import routerPath from '@/router/routerPath.js';

export default class NoticeDetailViewModel {
  constructor() {
    this.isMobile = false;
    this.detailData = {};
  }
  init(id){
    this.getNotcieDetail(id);
  }
  // 상세
  getNotcieDetail(id){
    const path = `${apiPath.NOTICE_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.detailData = resultData;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}