<template>
  <PageWithLayout>
    <BoardView
      :detailData="viewModel.detailData"
      :isShare="false"
      :isDate="true"
      :isFooterBtnControaler="true"/>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import BoardView from '@/components/common/board/BoardView';

// viewModel
import NoticeDetailViewModel from '@/views/blog/viewModel/notice/NoticeDetailViewModel';

export default {
  name:'NoticeDetail',
  components:{
    PageWithLayout,
    BoardView
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  watch:{
    '$route.params.id':{
      deep:true,
      handler(newVal,oldVal){
        if(newVal != oldVal){
          this.viewModel.getNotcieDetail(newVal)
        }
      }
    }
  },
  data(){
    return{
      viewModel: new NoticeDetailViewModel(),
    }
  },
  metaInfo: {
    meta: [
      {
        property: "description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"og:description",
      },
      {
        property: "twitter:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>
</style>